import React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import PageHeader from '../../components/PageHeader';
import Slide from '../../components/Slide';
import SlideShow from '../../components/SlideShow';

const NUMBER_OF_SLIDES = 50;
const SLIDE_NAME = 'hvad-er-metakognitiv-terapi';
const SLUG = '/haefter/diasshow-til-børn-om-metakognitiv-terapi';
const TITLE = 'Diasshow til børn om metakognitiv terapi';
const SUBTITLE =
  'Hvad er det lige, dit barn skal gøre med de der generende bekymringer? Det giver et sjovt og lærerigt diasshow svar på';

export default function Page() {
  const slides = [...Array(NUMBER_OF_SLIDES).keys()].map((i) => (
    <Slide index={i + 1} total={NUMBER_OF_SLIDES} name={SLIDE_NAME} />
  ));

  return (
    <Layout>
      <Seo title={TITLE} description={SUBTITLE} url={SLUG} />

      <div className="columns is-centered">
        <div className="column text content">
          <PageHeader title={TITLE} subtitle={SUBTITLE} />

          <hr />

          <p>
            Hvad er egentlig formålet med metakognitiv terapi? Det er et
            brandgodt spørgsmål, som den folkekære tv-læge Peter Qvortrup
            Geisling ville have udtrykt det. På enkel og børnevenlig vis guider
            jeg børnene gennem grundprincipperne i metakognitiv terapi. Så har
            du børn ned til 7-8 årsalderen, der skal i metakognitiv terapi, så
            er her muligheden for at give dem en lynintroduktion til terapien på
            en sjov og lærerig måde.
          </p>
        </div>
      </div>

      <SlideShow slides={slides} />
    </Layout>
  );
}
